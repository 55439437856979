<template>
  <v-container fluid>
    <h2>unterschriebener Wettkampfbericht hochladen</h2>
    <p>Benötigt werden:</p>
    <ul>
      <li>Unterschriebener <u>Wettkampfbericht</u>, der <b>VOR</b> dem Wettkampf befüllt werden muss</li>
      <li>Unterschriebener <u>Wettkampfbericht</u>, der <b>NACH</b> dem Wettkampf befüllt werden muss</li>
      <li><u>Wettkampf<b>ergebnis</b></u> mit Unterschriften.</li>
    </ul>
    <p>Danach die Originale bitte noch bis nach der Liga aufbewahren, falls es Streitfälle gibt und wir die Dokumente im Original benötigen.</p>
    <p>Hinweis: Der Upload der Wettkampfberichte ist nur nötig, falls <b>NICHT</b> direkt im Browser in den entsprechenden Feldern unterschrieben wurde!</p>
    <vue-dropzone
      ref="attachments"
      id="attachments"
      :options="dropzoneOptions"
    />
    <h3 style="margin-top:32px;">hochgeladene Dateien:</h3>
    <ul>
      <li v-for="a in attachments" :key="a._id">
        <a :href="`${imageBase}${a._id}`" :download="a.filename" target="_blank">{{ a.filename }}</a>
        ({{ a.mimetype }})
        <v-btn v-if="isMaster" fab text x-small @click="delAttachment(a._id)"><v-icon>far fa-trash-alt</v-icon></v-btn>
      </li>
    </ul>
  </v-container>
</template>

<script>
import { usePdf } from '@/views/components/stb_m_2021/plugins/pdf'
import gql from 'graphql-tag'
import { useGraphQL } from '@/plugins/graphql'
import { GRAPHQLlocal, GRAPHQLserver } from '@/env'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import Vue from 'vue'

export default {
  name: 'wkbericht',

  components: {
    VueDropzone: vue2Dropzone
  },

  setup (props, context) {
    return {
      ...usePdf(context),
      ...useGraphQL(context)
    }
  },

  props: {
    id: {
      type: String,
      required: true
    },
    e: {
      type: Object,
      required: true
    },
    df: {
      type: Array,
      required: true
    }
  },

  data () {
    return {
      dropzoneOptions: {
        url: () => {},
        accept: this.addAttachment,
        thumbnailWidth: 150,
        addRemoveLinks: true,
        autoProcessQueue: false
      }
    }
  },

  computed: {
    info () {
      return this.e.info || {}
    },
    attachments () {
      return this.info?.attachments?.filter(a => !a.filename.match(/^unterschrift/)) || []
    },
    imageBase () {
      if (!GRAPHQLlocal) return '/uploads/'

      const base = GRAPHQLserver.match(/(wss?):\/\/([^/]*)/)
      return `http${base[1] === 'wss' ? 's' : ''}://${base[2]}/uploads/`
    },
    isMaster () {
      return this.$store.getters.isMaster
    }
  },

  methods: {
    down1 () {
      this.wkbericht1(this.e, this.df, this.info.wettkampfleiter || '', this.info.hygienebeauftragter || '', this.info.ende || '', this.info.zuschauer || '', this.info.dauer || '', this.info.special || '')
    },
    down2 () {
      this.wkbericht2(this.e, this.info.ersthelfer || '', !!this.info.unfallhilfe, this.info.rettungsdienst || '', this.info.geraetenorm || '')
    },
    s (field, value) {
      this.mutate({
        mutation: gql`mutation($id: UUID!,
          $ersthelfer: String, $unfallhilfe: Boolean, $rettungsdienst: String,
          $geraetenorm: String, $wettkampfleiter: String, $hygienebeauftragter: String,
          $zuschauer: Int, $ende: String, $dauer: String, $special: String) {
          StbM2021WkInfoUpdate(id: $id,
            ersthelfer: $ersthelfer, unfallhilfe: $unfallhilfe, rettungsdienst: $rettungsdienst,
            geraetenorm: $geraetenorm, wettkampfleiter: $wettkampfleiter, hygienebeauftragter: $hygienebeauftragter,
            zuschauer: $zuschauer, ende: $ende, dauer: $dauer, special: $special) { _id }
        }`,
        variables: {
          id: this.id,
          [field]: value
        }
      })
    },
    async addAttachment (files) {
      const toBase64 = file => new Promise((resolve) => {
        var reader = new FileReader()
        reader.onload = () => resolve(reader.result)
        reader.readAsDataURL(file)
      })

      const filename = files.name
      const mimetype = files.type
      const filedata = await toBase64(files)

      this.$refs.attachments.removeFile(files)

      const neu = await this.mutate({
        mutation: gql`mutation($id: UUID!, $filedata: String!, $filename: String!, $mimetype: String!) {
          StbM2021WkInfoUpdateAttachmentAdd(id: $id, filedata: $filedata, filename: $filename, mimetype: $mimetype) {
            info { attachments { _id filename mimetype } }
          }
        }`,
        variables: {
          id: this.id,
          filedata,
          filename,
          mimetype
        }
      })

      Vue.set(this.e.info, 'attachments', neu.StbM2021WkInfoUpdateAttachmentAdd.info.attachments)
    },
    delAttachment (id) {
      this.mutate({
        mutation: gql`mutation($id: UUID!, $file: UUID!) {
          StbM2021WkInfoUpdateAttachmentDelete(id: $id, file: $file) {
            info { attachments { _id filename mimetype } }
          }
        }`,
        variables: {
          id: this.id,
          file: id
        }
      }).then(neu => {
        Vue.set(this.e.info, 'attachments', neu.StbM2021WkInfoUpdateAttachmentDelete.info.attachments)
      })
    }
  }
}
</script>

<style scoped>

</style>
